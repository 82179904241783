import { createInvalidStateError } from '../factories/invalid-state-error';
import { TNativeChannelSplitterNode } from '../types';

export const wrapChannelSplitterNode = (channelSplitterNode: TNativeChannelSplitterNode): void => {
    const channelCount = channelSplitterNode.numberOfOutputs;

    // Bug #97: Safari does not throw an error when attempting to change the channelCount to something other than its initial value.
    Object.defineProperty(channelSplitterNode, 'channelCount', {
        get: () => channelCount,
        set: (value) => {
            if (value !== channelCount) {
                throw createInvalidStateError();
            }
        }
    });

    /*
     * Bug #30: Only Chrome, Firefox & Opera throw an error when attempting to change the channelCountMode to something other than
     * explicit.
     */
    Object.defineProperty(channelSplitterNode, 'channelCountMode', {
        get: () => 'explicit',
        set: (value) => {
            if (value !== 'explicit') {
                throw createInvalidStateError();
            }
        }
    });

    /*
     * Bug #32: Only Chrome, Firefox & Opera throws an error when attempting to change the channelInterpretation to something other than
     * discrete.
     */
    Object.defineProperty(channelSplitterNode, 'channelInterpretation', {
        get: () => 'discrete',
        set: (value) => {
            if (value !== 'discrete') {
                throw createInvalidStateError();
            }
        }
    });
};
