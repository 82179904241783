import $ from "jquery";
import {ROUNDWARE_SERVER_BASE_URL} from "./roundware";
import {mapMarkerB, icons} from "./inlines";
import MarkerClusterer from "@google/markerclustererplus";
import alertify from "alertifyjs";

/**
 * Generate an indented list of links from a nav. Meant for use with panel().
 * @return {jQuery} jQuery object.
 */
$.fn.navList = function() {

  var $this = $(this);
  $a = $this.find('a'),
    b = [];

  $a.each(function() {

    var $this = $(this),
      indent = Math.max(0, $this.parents('li').length - 1),
      href = $this.attr('href'),
      target = $this.attr('target');

    b.push(
      '<a ' +
      'class="link depth-' + indent + '"' +
      ((typeof target !== 'undefined' && target != '') ? ' target="' + target + '"' : '') +
      ((typeof href !== 'undefined' && href != '') ? ' href="' + href + '"' : '') +
      '>' +
      '<span class="indent-' + indent + '"></span>' +
      $this.text() +
      '</a>'
    );

  });

  return b.join('');

};

/**
 * Panel-ify an element.
 * @param {object} userConfig User config.
 * @return {jQuery} jQuery object.
 */
$.fn.panel = function(userConfig) {

  // No elements?
  if (this.length == 0)
    return $this;

  // Multiple elements?
  if (this.length > 1) {

    for (var i = 0; i < this.length; i++)
      $(this[i]).panel(userConfig);

    return $this;

  }

  // Vars.
  var $this = $(this),
    $body = $('body'),
    $window = $(window),
    id = $this.attr('id'),
    config;

  // Config.
  config = $.extend({

    // Delay.
    delay: 0,

    // Hide panel on link click.
    hideOnClick: false,

    // Hide panel on escape keypress.
    hideOnEscape: false,

    // Hide panel on swipe.
    hideOnSwipe: false,

    // Reset scroll position on hide.
    resetScroll: false,

    // Reset forms on hide.
    resetForms: false,

    // Side of viewport the panel will appear.
    side: null,

    // Target element for "class".
    target: $this,

    // Class to toggle.
    visibleClass: 'visible'

  }, userConfig);

  // Expand "target" if it's not a jQuery object already.
  if (typeof config.target != 'jQuery')
    config.target = $(config.target);

  // Panel.

  // Methods.
  $this._hide = function(event) {

    // Already hidden? Bail.
    if (!config.target.hasClass(config.visibleClass))
      return;

    // If an event was provided, cancel it.
    if (event) {

      event.preventDefault();
      event.stopPropagation();

    }

    // Hide.
    config.target.removeClass(config.visibleClass);

    // Post-hide stuff.
    window.setTimeout(function() {

      // Reset scroll position.
      if (config.resetScroll)
        $this.scrollTop(0);

      // Reset forms.
      if (config.resetForms)
        $this.find('form').each(function() {
          this.reset();
        });

    }, config.delay);

  };

  // Vendor fixes.
  $this
    .css('-ms-overflow-style', '-ms-autohiding-scrollbar')
    .css('-webkit-overflow-scrolling', 'touch');

  // Hide on click.
  if (config.hideOnClick) {

    $this.find('a')
      .css('-webkit-tap-highlight-color', 'rgba(0,0,0,0)');

    $this
      .on('click', 'a', function(event) {

        var $a = $(this),
          href = $a.attr('href'),
          target = $a.attr('target');

        if (!href || href == '#' || href == '' || href == '#' + id)
          return;

        // Cancel original event.
        event.preventDefault();
        event.stopPropagation();

        // Hide panel.
        $this._hide();

        // Redirect to href.
        window.setTimeout(function() {

          if (target == '_blank')
            window.open(href);
          else
            window.location.href = href;

        }, config.delay + 10);

      });

  }

  // Event: Touch stuff.
  $this.on('touchstart', function(event) {

    $this.touchPosX = event.originalEvent.touches[0].pageX;
    $this.touchPosY = event.originalEvent.touches[0].pageY;

  })

  $this.on('touchmove', function(event) {

    if ($this.touchPosX === null
      || $this.touchPosY === null)
      return;

    var diffX = $this.touchPosX - event.originalEvent.touches[0].pageX,
      diffY = $this.touchPosY - event.originalEvent.touches[0].pageY,
      th = $this.outerHeight(),
      ts = ($this.get(0).scrollHeight - $this.scrollTop());

    // Hide on swipe?
    if (config.hideOnSwipe) {

      var result = false,
        boundary = 20,
        delta = 50;

      switch (config.side) {

        case 'left':
          result = (diffY < boundary && diffY > (-1 * boundary)) && (diffX > delta);
          break;

        case 'right':
          result = (diffY < boundary && diffY > (-1 * boundary)) && (diffX < (-1 * delta));
          break;

        case 'top':
          result = (diffX < boundary && diffX > (-1 * boundary)) && (diffY > delta);
          break;

        case 'bottom':
          result = (diffX < boundary && diffX > (-1 * boundary)) && (diffY < (-1 * delta));
          break;

        default:
          break;

      }

      if (result) {

        $this.touchPosX = null;
        $this.touchPosY = null;
        $this._hide();

        return false;

      }

    }

    // Prevent vertical scrolling past the top or bottom.
    if (($this.scrollTop() < 0 && diffY < 0)
      || (ts > (th - 2) && ts < (th + 2) && diffY > 0)) {

      event.preventDefault();
      event.stopPropagation();

    }

  });

  // Event: Prevent certain events inside the panel from bubbling.
  $this.on('click touchend touchstart touchmove', function(event) {
    event.stopPropagation();
  });

  // Event: Hide panel if a child anchor tag pointing to its ID is clicked.
  $this.on('click', 'a[href="#' + id + '"]', function(event) {

    event.preventDefault();
    event.stopPropagation();

    config.target.removeClass(config.visibleClass);

  });

  // Body.

  // Event: Hide panel on body click/tap.
  $body.on('click touchend', function(event) {
    $this._hide(event);
  });

  // Event: Toggle.
  $body.on('click', 'a[href="#' + id + '"]', function(event) {

    event.preventDefault();
    event.stopPropagation();

    config.target.toggleClass(config.visibleClass);

  });

  // Window.

  // Event: Hide on ESC.
  if (config.hideOnEscape)
    $window.on('keydown', function(event) {

      if (event.keyCode == 27)
        $this._hide(event);

    });

  return $this;

};

/**
 * Apply "placeholder" attribute polyfill to one or more forms.
 * @return {jQuery} jQuery object.
 */
$.fn.placeholder = function() {

  // Browser natively supports placeholders? Bail.
  if (typeof (document.createElement('input')).placeholder != 'undefined')
    return $(this);

  // No elements?
  if (this.length == 0)
    return $this;

  // Multiple elements?
  if (this.length > 1) {

    for (var i = 0; i < this.length; i++)
      $(this[i]).placeholder();

    return $this;

  }

  // Vars.
  var $this = $(this);

  // Text, TextArea.
  $this.find('input[type=text],textarea')
    .each(function() {

      var i = $(this);

      if (i.val() == ''
        || i.val() == i.attr('placeholder'))
        i
          .addClass('polyfill-placeholder')
          .val(i.attr('placeholder'));

    })
    .on('blur', function() {

      var i = $(this);

      if (i.attr('name').match(/-polyfill-field$/))
        return;

      if (i.val() == '')
        i
          .addClass('polyfill-placeholder')
          .val(i.attr('placeholder'));

    })
    .on('focus', function() {

      var i = $(this);

      if (i.attr('name').match(/-polyfill-field$/))
        return;

      if (i.val() == i.attr('placeholder'))
        i
          .removeClass('polyfill-placeholder')
          .val('');

    });

  // Password.
  $this.find('input[type=password]')
    .each(function() {

      var i = $(this);
      var x = $(
        $('<div>')
          .append(i.clone())
          .remove()
          .html()
          .replace(/type="password"/i, 'type="text"')
          .replace(/type=password/i, 'type=text')
      );

      if (i.attr('id') != '')
        x.attr('id', i.attr('id') + '-polyfill-field');

      if (i.attr('name') != '')
        x.attr('name', i.attr('name') + '-polyfill-field');

      x.addClass('polyfill-placeholder')
        .val(x.attr('placeholder')).insertAfter(i);

      if (i.val() == '')
        i.hide();
      else
        x.hide();

      i
        .on('blur', function(event) {

          event.preventDefault();

          var x = i.parent().find('input[name=' + i.attr('name') + '-polyfill-field]');

          if (i.val() == '') {

            i.hide();
            x.show();

          }

        });

      x
        .on('focus', function(event) {

          event.preventDefault();

          var i = x.parent().find('input[name=' + x.attr('name').replace('-polyfill-field', '') + ']');

          x.hide();

          i
            .show()
            .focus();

        })
        .on('keypress', function(event) {

          event.preventDefault();
          x.val('');

        });

    });

  // Events.
  $this
    .on('submit', function() {

      $this.find('input[type=text],input[type=password],textarea')
        .each(function(event) {

          var i = $(this);

          if (i.attr('name').match(/-polyfill-field$/))
            i.attr('name', '');

          if (i.val() == i.attr('placeholder')) {

            i.removeClass('polyfill-placeholder');
            i.val('');

          }

        });

    })
    .on('reset', function(event) {

      event.preventDefault();

      $this.find('select')
        .val($('option:first').val());

      $this.find('input,textarea')
        .each(function() {

          var i = $(this),
            x;

          i.removeClass('polyfill-placeholder');

          switch (this.type) {

            case 'submit':
            case 'reset':
              break;

            case 'password':
              i.val(i.attr('defaultValue'));

              x = i.parent().find('input[name=' + i.attr('name') + '-polyfill-field]');

              if (i.val() == '') {
                i.hide();
                x.show();
              }
              else {
                i.show();
                x.hide();
              }

              break;

            case 'checkbox':
            case 'radio':
              i.attr('checked', i.attr('defaultValue'));
              break;

            case 'text':
            case 'textarea':
              i.val(i.attr('defaultValue'));

              if (i.val() == '') {
                i.addClass('polyfill-placeholder');
                i.val(i.attr('placeholder'));
              }

              break;

            default:
              i.val(i.attr('defaultValue'));
              break;

          }
        });

    });

  return $this;

};

/**
 * Moves elements to/from the first positions of their respective parents.
 * @param {jQuery} $elements Elements (or selector) to move.
 * @param {bool} condition If true, moves elements to the top. Otherwise, moves elements back to their original locations.
 */
$.prioritize = function($elements, condition) {

  var key = '__prioritize';

  // Expand $elements if it's not already a jQuery object.
  if (typeof $elements != 'jQuery')
    $elements = $($elements);

  // Step through elements.
  $elements.each(function() {

    var $e = $(this), $p,
      $parent = $e.parent();

    // No parent? Bail.
    if ($parent.length == 0)
      return;

    // Not moved? Move it.
    if (!$e.data(key)) {

      // Condition is false? Bail.
      if (!condition)
        return;

      // Get placeholder (which will serve as our point of reference for when this element needs to move back).
      $p = $e.prev();

      // Couldn't find anything? Means this element's already at the top, so bail.
      if ($p.length == 0)
        return;

      // Move element to top of parent.
      $e.prependTo($parent);

      // Mark element as moved.
      $e.data(key, $p);

    }

    // Moved already?
    else {

      // Condition is true? Bail.
      if (condition)
        return;

      $p = $e.data(key);

      // Move element back to its original location (using our placeholder).
      $e.insertAfter($p);

      // Unmark element as moved.
      $e.removeData(key);

    }

  });

};

function voteAsset(assetId, voteType, value) {
  let rw = window[window['Roundware']];
  console.log(`Asset #${assetId}: vote registered`);
  rw.client.vote(assetId, voteType, value);
  alertify.success(`${voteType} registered!`);
  if (voteType === "flag") {
    $(`#${voteType}${assetId}`).css('color', 'red');
  } else if (voteType === "like") {
    $(`#${voteType}${assetId}`).css('color', 'blue');
  }
  // disallow multiple votes
  $(`#${voteType}${assetId}`).off('click');
}
// custom geographic functions
/**
 * Calculates the distance between two {@link Point|points} in degress, radians,
 * miles, or kilometers. This uses the [Haversine formula](http://en.wikipedia.org/wiki/Haversine_formula)
 * to account for global curvature.
 *
 * Modified from https://github.com/Turfjs/turf-distance/blob/a79f9dcc4402e0244fbcd3b7f36d9b361d9032bf/index.js */
export const distance = (coordinates1, coordinates2) => {
  // returns distance between points, measured in kilometers
  const dLat = toRad(coordinates2[1] - coordinates1[1]);
  const dLon = toRad(coordinates2[0] - coordinates1[0]);
  const lat1 = toRad(coordinates1[1]);
  const lat2 = toRad(coordinates2[1]);

  const a = Math.pow(Math.sin(dLat / 2), 2) +
    Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return 6373 * c;
}

function toRad(degree) {
  return degree * Math.PI / 180;
}

export function wobble(input, amount) {
  const wobbleAmount = (Math.random() * amount * 2) - amount;
  return input + wobbleAmount;
}

export const mapAssets = async (map, oms, listener, rw) => {
  let roundware = rw;
  if (roundware === undefined) {
    roundware = window[window['Roundware']].client;
  }
  const assets = await roundware.loadAssetPool();

  const assetMarkers = [];
  let markerImage = {
    // hack (evan) avoid bundling the icon during build
    url: mapMarkerB,
    // This marker is 50 pixels wide by 50 pixels high.
    scaledSize: new google.maps.Size(30, 30),
    // The origin for this image is (0, 0).
    origin: new google.maps.Point(0, 0),
    // The anchor for this image is the base of the point.
    anchor: new google.maps.Point(15, 30)
  };

  assets.forEach(item => {
    const point = new google.maps.LatLng(wobble(item.latitude, .01), wobble(item.longitude, .01));

    const marker = new google.maps.Marker({
      map,
      position: point,
      icon: markerImage
    });

    marker.id = item.id;
    marker.rw_tags = [];

    if (item.tag_ids) {
      marker.rw_tags = item.tag_ids;
    }

    const { id: itemId, file, volume, created = '', weight, tag_ids } = item;

    const tagHtml = tag_ids.map(t => '<p class="infoTagText">' + roundware.findTagDescription(t) + "</p>").join("");
    const date = new Date(created);
    const dateHtml = '<p class="infoDateText"><em>' + date.toDateString() + '</em></p>';

    const filePathMp3 = ROUNDWARE_SERVER_BASE_URL + "rwmedia/" +
      item.filename.substring(0, item.filename.lastIndexOf('.')).concat(".mp3");
    const filePathWav = ROUNDWARE_SERVER_BASE_URL + "rwmedia/" +
      item.filename.substring(0, item.filename.lastIndexOf('.')).concat(".wav");

    const content = `
    ${tagHtml}
    ${dateHtml}
    <audio controls preload="none" controlsList="nodownload">
      <source src="${filePathMp3}" type="audio/mp3" >
      <source src="${filePathWav}" type="audio/wav" >
      Your browser does not support audio!
    </audio>
    <div id="infoVoteBlock">
      <button class="markerAction"  
        id="like${itemId}" 
        title="tell us you like this one!" 
        >${icons.thumbsUp.html}</button>
      <button class="markerAction"  
        id="flag${itemId}" 
        title="flag this content for review"
        >${icons.flag.html}</button>
      <button class="markerAction" 
        title="go to contribution page" 
        id="link${itemId}"
        >${icons.link.html}</button>
      <button class="markerAction" 
        id="download${itemId}" 
        title="download this audio file"
        >${icons.download.html}</button>
    </div>`;

    const infoWindow = new google.maps.InfoWindow({ content });

    marker.addListener('spider_click', function() {
      if (roundware.activeInfoWindow) { roundware.activeInfoWindow.close(); }
      infoWindow.open(map, marker);
      roundware.activeInfoWindow = infoWindow;

      const assetLocationPoint = [
        marker.position.lng(),
        marker.position.lat()
      ];

      const listenerLocationPoint = [
        listener.position.lng(),
        listener.position.lat()
      ];

      const dist = distance(listenerLocationPoint, assetLocationPoint);

      console.info(`Asset #${marker.id}: ${dist.toFixed(1)}km from listener`);
    });

    google.maps.event.addListener(infoWindow,'domready',function(){
      $(`#flag${itemId}`).click(function() {
      });
    });
    google.maps.event.addListener(infoWindow,'domready',function(){
      $(`#like${itemId}`).click(function() {
        voteAsset(itemId, 'like');
      });
    });
    google.maps.event.addListener(infoWindow,'domready',function(){
      $(`#link${itemId}`).click(function() {
        const url = `https://coronadiaries.io/s.html?eid=${item.envelope_ids[0]}`
        console.log(url);
        window.open(url, '_blank');
      });
    });
    google.maps.event.addListener(infoWindow,'domready',function(){
      $(`#download${itemId}`).click(function() {
        fetch(`${filePathMp3}`, {
          headers: new Headers({
            'Origin': location.origin
          }),
          mode: 'cors'
        })
          .then(response => response.blob())
          .then(blob => {
            let blobUrl = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.download = `corona_diaries_${itemId}`;
            a.href = blobUrl;
            // For Firefox https://stackoverflow.com/a/32226068
            document.body.appendChild(a);
            a.click();
            a.remove();
          })
          .catch(e => console.error(e));
      });
    });

    // display asset shape if exists
    if (item.shape) {
      marker.shape = new google.maps.Data();
      marker.shape.addGeoJson({
        "type": "Feature",
        "geometry": item.shape,
        "properties": {
          "asset_id": item.id,
          "name": "assetRange"
        }
      });

      marker.shape.setStyle(feature => {
        if (feature.getProperty('name') === "assetRange") {
          return {
            fillColor: '#6292CF',
            fillOpacity: .25,
            strokeWeight: 1,
            strokeOpacity: .8,
            strokeColor: '#6292CF'
          };
        } else {
          return {};
        }
      });
    } else {
      // if no asset shape, display default circle range
      const circle = {
        strokeColor: '#6292CF',
        strokeOpacity: 0.8,
        strokeWeight: 1,
        fillColor: '#6292CF',
        fillOpacity: 0.25,
        map: map,
        center: new google.maps.LatLng(item.latitude, item.longitude),
        radius: roundware._project.recordingRadius
      };

      // marker.circle = new google.maps.Circle(circle);
    }

    assetMarkers.push(marker);
  });

  showHideMarkers(map, oms, assetMarkers);
  return assetMarkers;
}

export const showHideMarkers = (map, oms, assetMarkers) => {
  const cluster = new MarkerClusterer(map, assetMarkers, {
    maxZoom: 12,
    minimumClusterSize: 3,
    imagePath: "https://github.com/googlemaps/v3-utility-library/raw/master/packages/markerclustererplus/images/m"
  });
  assetMarkers.forEach(m => oms.addMarker(m));
  // assetMarkers.forEach(item => {
  // if any item tags are not included in selected tags, hide marker, otherwise show it
  //const selectedListenTagIds = $("#uiListenDisplay input:checked").map(function() {
  //return Number(this.value);
  //}).get();

  //let is_visible = true;

  //const { rw_tags = [] } = item;

  //rw_tags.forEach((j,tag_id) =>{
  //// if tag_id isn't selected, set to false and return
  //if (!(selectedListenTagIds.includes(tag_id))) {
  //is_visible = false;
  //return;
  //}
  //});

  // const is_visible = true;

  // item.setVisible(is_visible);

  // if (item.circle) {
  //   item.circle.setVisible(is_visible);
  // }
  // if (item.shape) {
  //   if (is_visible) {
  //     item.shape.setMap(map);
  //   } else if (!is_visible) {
  //     item.shape.setMap(null);
  //   }
  // }
  // });
}

export const drawListeningCircle = (map, center, radius) => (
  new google.maps.Circle({
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    map,
    center,
    radius
  })
);
