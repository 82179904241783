import { nanoid } from "nanoid";
import Cookies from "js-cookie";
import { Roundware } from "roundware-web-framework";

// const ROUNDWARE_SERVER_URL = 'http://localhost:8888/api/2';
// export const ROUNDWARE_SERVER_BASE_URL = 'http://localhost:8888/';
const ROUNDWARE_SERVER_URL = 'https://prod.roundware.com/api/2';
export const ROUNDWARE_SERVER_BASE_URL = 'https://prod.roundware.com/';
const ROUNDWARE_DEFAULT_PROJECT_ID = 27;
export const ROUNDWARE_INITIAL_LATITUDE = 36.728342;
export const ROUNDWARE_INITIAL_LONGITUDE = -118.150453;

export const ROUNDWARE_INITIAL_LOC = {
  latitude: ROUNDWARE_INITIAL_LATITUDE,
  longitude: ROUNDWARE_INITIAL_LONGITUDE
};

export const getDeviceId = () => {
  const existingId = Cookies.get("deviceId");
  if (existingId) {
    return existingId;
  } else {
    const newId = nanoid();
    Cookies.set("deviceId", newId, { expires: 365 });
    return newId;
  }
}

export const roundware = new Roundware(window, {
  serverUrl: ROUNDWARE_SERVER_URL,
  projectId: ROUNDWARE_DEFAULT_PROJECT_ID,
  geoListenEnabled: true,
  speakerFilters: { activeyn: true },
  assetFilters: { submitted: true, media_type: "audio" },
  listenerLocation: ROUNDWARE_INITIAL_LOC,
  deviceId: getDeviceId(),
});

export const setup_roundware = (options) => {
  const forcedFilters = { submitted: true, media_type: "audio" };
  const defaults = {
    serverUrl: ROUNDWARE_SERVER_URL,
    projectId: ROUNDWARE_DEFAULT_PROJECT_ID,
    geoListenEnabled: false,
    speakerFilters: { activeyn: true },
    listenerLocation: ROUNDWARE_INITIAL_LOC,
    deviceId: getDeviceId(),
  }
  const assetFilters = Object.assign({}, options.assetFilters || {}, forcedFilters);
  const finalOptions = Object.assign({}, options, defaults);
  finalOptions.assetFilters = assetFilters;
  return new Roundware(window, finalOptions);
}
